import * as React from "react";
import Privacy from "components/landing-page/site/Privacy";
import { graphql } from 'gatsby'
// import ThemeProvider from "./ThemeProvider";
// markup
const PrivacyPage = ({}) => {
  return <Privacy/>
};


export default PrivacyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;